.App {
  text-align: center;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  height: 100%;
  background-color: #000502;
  color: #4ded9a;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
body,
button,
input,
select,
textarea {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  overflow-y: auto;
}
.uppercase {
  text-transform: uppercase;
}
.prose > :first-child {
  margin-top: 0;
}
.text-primary {
  --tw-text-opacity: 1;
  color: #4ded9a;
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 74, 24, var(--tw-bg-opacity));
}
.w-52 {
  width: 13rem;
}
.h-1 {
  height: 0.25rem;
}

nav {
  background-color: #000000;
}
#root {
  height: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

.faq-section p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.faq-question-li {
  list-style-type: disc;
  margin-bottom: 10px;
}
ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.faq-section a {
  color: #3b82f6;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-item img {
  height: 310px;
}

@media only screen and (max-width: 600px) {
  .carousel-item img {
    height: 100px;
  }
}

.main_logo {
  width: 150px;
}

@media only screen and (max-width: 600px) {
  .main_logo {
    height: 100px;
  }
}

.main-header {
  font-size: 2.25rem;
  line-height: 2.25rem;
}
.sub-header {
  font-size: 24px;
}

@media only screen and (max-width: 600px) {
  .main-header {
    font-size: 16px;
  }
  .sub-header {
    font-size: 12px;
  }
}

.img1 {
  width: 280px;
  height: 51px;
}
.img2 {
  width: 196px;
  height: 195px;
}

.img3 {
  width: 174px;
  height: 174px;
}
.header {
  background-color: #000;
}
.headerTxt {
  font-family: oswald-medium, oswald, sans-serif;
  line-height: normal;
}

/* Tab content - closed */
.tab-content {
  max-height: 0;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
  object-position: 50% 50%;
}
/* :checked - resize to full height */
.tab2 input:checked ~ .tab-content {
  max-height: 120vh;
}
/* Label formatting when open */
.tab2 input + label {
  padding: 1.25rem; /*.p-5*/
}
/* Icon */
.tab2 label::after {
  float: right;
  right: 0;
  top: 0;
  display: block;
  width: 24px;
  height: 24px;
  text-align: center;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
/* Icon formatting - closed */
.tab2 input[type='checkbox'] + label::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='text-white bg-blue-500 w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 6v6m0 0v6m0-6h6m-6 0H6' /%3E%3C/svg%3E");
  font-weight: bold; /*.font-bold*/
  border-radius: 9999px; /*.rounded-full */
  min-width: 24px;
}

.tab2 input[type='radio'] + label::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='w-6 h-6' fill='none' color='white' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 6v6m0 0v6m0-6h6m-6 0H6' /%3E%3C/svg%3E");
  font-weight: bold; /*.font-bold*/
  border-radius: 9999px; /*.rounded-full */
  font-size: 26px;
  min-width: 24px;
}

/* Icon formatting - open */
.tab2 input[type='checkbox']:checked + label::after {
  content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' class='text-gray-400 bg-gray w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M18 12H6' /%3E%3C/svg%3E");
  font-weight: bold; /*.font-bold*/
  font-size: 16px;
  min-width: 24px;
}

.tab2 input[type='radio']:checked + label::after {
  transform: rotateX(180deg);
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
  color: #2b6cb0; /*Set to match the Tailwind colour you want the active one to be */
}

/* Animations */
.rotate-center {
  animation: rotate-center 0.6s ease-in-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-2 19:40:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/* ----------------------------------------------
 * Generated by Animista on 2022-10-2 19:44:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-display: block;
  font-family: 'Oswald-Medium';
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajFtXRa8TVwTICgirnJhmVJw.woff2')
      format('woff2'),
    url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajD8E0i7KZn-EPnyo3HZu7kw.woff')
      format('woff'),
    url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajKCWcynf_cDxXwCLxiixG1c.ttf')
      format('truetype'),
    url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/oswald-medium-Svg.svg')
      format('svg');
}
@font-face {
  font-display: block;
  font-family: 'Oswald-Medium';
  font-weight: 700;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb31tXRa8TVwTICgirnJhmVJw.woff2')
      format('woff2'),
    url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb3z8E0i7KZn-EPnyo3HZu7kw.woff')
      format('woff'),
    url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb36CWcynf_cDxXwCLxiixG1c.ttf')
      format('truetype'),
    url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/oswald-medium-700-Svg.svg')
      format('svg');
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752FD8Gl-1PK62t.woff2')
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752HT8Gl-1PK62t.woff2')
    format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752Fj8Gl-1PK62t.woff2')
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752Fz8Gl-1PK62t.woff2')
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2')
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752FD8Gl-1PK62t.woff2')
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752HT8Gl-1PK62t.woff2')
    format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752Fj8Gl-1PK62t.woff2')
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752Fz8Gl-1PK62t.woff2')
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2')
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.rainbowkit-page {
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .w-full-xs {
    width: 100%;
  }
}
