@import '../../styles/helpers.scss';

.connect-wallet-btn {
  display: flex;
  align-items: center;
  padding-left: 6rem;
  padding-right: 6rem;

  @include t {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @include s {
    padding-left: 1.5rem;
    padding-left: 1.5rem;
  }
}
